@font-face {
    font-family: 'FuenteDigitalHaus';
    src: url('../assets/fonts/FUTURALT-EXTRABOLD.TTF') format('truetype');
}
a{
    color: inherit!important;
}
i {
    padding: 5px;
    font-size: 22px;
}
.logoHeader{
    width: 4em;
    margin-left: 5px;
}
.headerBanner {
    margin-right: 20px;
    margin-top: 5px;
    height: 6vh;
}
