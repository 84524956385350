@font-face {
    font-family: 'Futura Regular';
    src: url('../assets/fonts/FUTURALT-LIGHT.TTF');
}

@font-face {
    font-family: 'Futura ExtraBold';
    src: url('../assets/fonts/FUTURALT-EXTRABOLD.TTF');
}

@font-face {
    font-family: 'Futura Oblique';
    src: url('../assets/fonts/FUTURALT-OBLIQUE.TTF');
}