.containerPrincing{
    font-size: 30px;
    text-align: justify;
    font-family: 'FuenteRegular'!important;
}
.fuenteBold{
    font-family: 'FuenteBold'!important;
}
/* Móvil */
@media screen and (max-width: 430px) {
    p{
        font-size: 20px;
    }
}