.image-container {
  position: relative;
  overflow: hidden;
}

.image {
  width: 100%;
  height: auto;
  transition: transform 0.5s;
}

.overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0;
  opacity: 0;
  transition: opacity 0.5s, height 0.5s;
  transform: translateY(100%);
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-container:hover .image {
  transform: scale(1.1);
}

.image-container:hover .overlay {
  height: 15%;
  opacity: 0.9;
  transform: translateY(0);
}


.carousel-container {
  width: 100%;
  overflow: hidden;
}

.carousel-text {
  white-space: nowrap;
  animation: carousel 11s linear infinite;
  display: inline-block; /* Agrega este estilo para que los elementos se muestren en línea */
}

.carousel-text span {
  padding-right: 30px; /* Espacio entre los elementos de texto */
}

@keyframes carousel {
  0% { transform: translateX(100%); } /* Cambia el valor de translateX para que inicie en 0% */
  100% { transform: translateX(-100%); } /* Cambia el valor de translateX para que termine en -100% */
}

/* Estilo para dispositivos móviles */
@media (max-width: 1020px) {
  .image-container .image {
    transform: scale(1.1);
  }
  
  .image-container .overlay {
    height: 15%;
    opacity: 0.9;
    transform: translateY(0);
  }
}


