/* Tipografias */
@font-face {
    font-family: 'FuenteLight';
    src: url('./assets/fonts/FUTURALT-LIGHT.TTF') format('truetype');
}
@font-face {
    font-family: 'FuenteRegular';
    src: url('./assets/fonts/FUTURALT.TTF') format('truetype');
}
@font-face {
    font-family: 'FuenteBold';
    src: url('./assets/fonts/FUTURA-LT-BOLD.OTF') format('truetype');
}
@font-face {
    font-family: 'FuenteBook';
    src: url('./assets/fonts/FUTURA-LT-BOOK.TTF') format('truetype');
}
.linea{
    height: 4px;
    width: 100%;
}
