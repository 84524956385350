@font-face {
    font-family: 'FuenteFooter';
    src: url('../assets/fonts/FUTURALT-LIGHT.TTF') format('truetype');
}
@font-face {
    font-family: 'FuenteFooterBold';
    src: url('../assets/fonts/FUTURA-LT-BOLD.OTF') format('truetype');
}
.footerContainer{
    position: relative;
    bottom: 0;
    left: 0;
}
.footer{
    background-image: url("../assets/img/fondofooter.png");
    background-size: cover;
}
.logoFooter{
    position: absolute;
    bottom: 0;
    right: 0;
    background-image: url("../assets/img/logofooter.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 19vh;
    width: 20vh;
    z-index: 1;
}
.bordeSup{
    height: 20px;
    background: linear-gradient(to right, #3C1252, #6F4063, #8B197C);
}
.labelText :nth-child(1){
    font-family: 'FuenteFooter';
    font-size: 35px;
}
.labelText :nth-child(2){
    font-family: 'FuenteFooterBold';
    font-size: 30px;
}