.fuenteBold{
    font-family: 'FuenteBold'!important;
}
.fuenteLight{
    font-family: 'FuenteLight'!important;
}
.aboutContainer{
    font-size: 24px;
    letter-spacing: 1px;
    word-spacing: 2px;
    overflow: hidden;
}
ol li b{
    font-family: 'FuenteBold';
}
.locationsContainer{
    width: 70%;
}
p{
    text-align: justify!important;
}
h1{
    font-size: 36px!important;
}
/* Tablet */
@media screen and (max-width: 770px) {
    .locationsContainer{
        width: 100%;
    }
    .aboutContainer {
        text-align: justify;
        font-size: 20px!important;
    }
}
/* Móvil */
@media screen and (max-width: 430px) {
    .h1{
        font-size: 40px!important;
    }
    .ourProcess{
        text-align: left;
        font-size: 20px;
    }
}