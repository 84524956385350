#secOne{
    background-image: url('../images/fondoweb.png');
}

#bod{
    padding-left: 5em;
    padding-right: 5em;
}

@media (max-width: 769px) {
    #secOne {
        background-image: url('../images/fondoweb.png');
        
    }
    #bod{
        padding-left: 1em;
        padding-right: 1em;
    }
}

.mosaic {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); /* Distribuye los elementos en columnas flexibles */
    grid-gap: 10px; /* Espacio entre los elementos */
}
  
.mosaic-item {
    position: relative;
}
  
.mosaic-item img {
    width: 100%;
    height: auto;
    display: block;
}
  
.mosaic-item::before {
    content: "";
    display: block;
}

#imMosaic{
    width: 100%;
    height: 30%;
}

p{
    color: #3C1252;
}
h5{
    color: #3C1252;
}
.accordion-button:not(.collapsed) {
    color: var(--bs-accordion-active-color);
    background-color: transparent !important;
    box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
}

.accordion {
    --bs-accordion-border-color: #3C1252 !important;
    --bs-accordion-border-width: 3px !important;
}