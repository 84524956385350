@font-face {
    font-family: 'FuenteDigitalHaus';
    src: url('../assets/fonts/FUTURALT-EXTRABOLD.TTF') format('truetype');
}

.logoDH {
    width: 14%;
    height: 20%;
    position: fixed;
    top: calc(50% - 5%);
    left: calc(50% - 7%);
}

.mainContainer {
    height: 100vh;
}
.footerBanner {
    height: 6vh;
}
.bodyBanner {
    height: 88vh;
    font-family: 'FuenteDigitalHaus';
    font-style: italic;
}
.left,
.right {
    width: 49.7%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    font-size: 10vh;
    cursor: pointer;
}

.left {
    background-image: url('../assets/img/perfil1.jpg');
}

.right {
    background-image: url('../assets/img/perrfil2.jpg');
}
.left div:hover, .right div:hover{
    font-size: 70px;
}
.screenSecundary{
    position: fixed;
}
@media screen and (max-width: 930px) {

    .left,
    .right {
        font-size: 7vh;
    }
}

/* Tablet */
@media screen and (max-width: 770px) {

    .left,
    .right {
        font-size: 6vh;
    }
}

/* Tablet small */
@media screen and (max-width: 570px) {

    .left,
    .right {
        width: 100% !important;
        height: 50vh;
    }

    .bodyBanner {
        flex-direction: column !important;
    }

    .right {
        margin-top: 5px;
    }
    
    .logoDH {
        width: 20%;
    }
}

/* Mobile */
@media screen and (max-width: 370px) {

    .left,
    .right {
        font-size: 4vh;
    }
}

/* keyFrame example
  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }
  
  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .App-link {
    color: #61dafb;
  }
  
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  */