@font-face {
    font-family: 'FuenteCompany';
    src: url('../assets/fonts/FUTURALT-OBLIQUE.TTF') format('truetype');
}
@font-face {
    font-family: 'FuenteCompanyBold';
    src: url('../assets/fonts/FUTURALT-EXTRABOLD.TTF') format('truetype');
}

.nav-link{ 
    font-family: 'FuenteCompany'!important;
    margin: 0;
    height: 20vh;
}
.nav-link i, .nav-link p{
    padding-left: 20px;
    padding-right: 20px;
    font-size: 24px;
    /* color: #C1B0E0!important; */
    margin-bottom: 0!important;
}
.nav-link:hover{
    font-family: 'FuenteCompanyBold'!important;
}